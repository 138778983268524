import React, { Suspense, useEffect, useState } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ToastContextProvider } from './ToastContext';


//import FontAwesome fileExt
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import {
  faCheckSquare,
  faCoffee,
  faList,
  faGauge,
  faFolderOpen,
  faFolderClosed,
  faPersonChalkboard,
  faChalkboardUser,
  faGears,
  faChartLine,
  faUsers,
  faPerson,
  faPersonCircleCheck,
  faSave,
  faEdit,
  faTrash,
  faExclamationTriangle,
  faBoxArchive,
  faLightbulb,
  faChevronRight,
  faCheckDouble,
  faCircleInfo,
  faHome,
  faFolder,
  faFile
} from '@fortawesome/free-solid-svg-icons';
import {IntlProvider} from "react-intl";

library.add(fab, faCheckSquare, faCoffee, faList, faGauge, faFolderOpen, faFolderClosed, faPersonChalkboard, faChalkboardUser, faGears, faChartLine, faUsers, faPerson, faPersonCircleCheck, faSave, faEdit, faTrash, faExclamationTriangle, faBoxArchive, faCircle, faCircleCheck, faLightbulb, faChevronRight, faCheckDouble, faCircleInfo, faHome, faFolder, faFile);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const SlideshowModel = React.lazy(() => import('./views/slideshows/SlideshowModel'));
const SlideType = React.lazy(() => import('./views/slideshows/SlideType'));
const Slideshow = React.lazy(() => import('./views/slideshows/Slideshow'));

function App() {
  const [locale, setLocale] = useState(navigator.language.split('-')[0]);

  useEffect(() => {
    const handleLanguageChanged = (newLocale) => {
      setLocale(newLocale);
    };

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, []);

  return (
    <IntlProvider locale={locale}>
      <I18nextProvider i18n={i18n}>
        <ToastContextProvider>
          <HashRouter>
            <Suspense fallback={loading}>
              <Routes>
                <Route exact path="/login" name="Login Page" element={<Login />} />
                {/*<Route exact path="/logout" name="Logout" element={<Logout/>}/>*/}
                <Route exact path="/register" name="Register Page" element={<Register />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />

                <Route path="*" name="Boards" element={<DefaultLayout />} />

                <Route exact path="/slideshowModels/:id/:name" name="SlideshowModel" element={<SlideshowModel />} />
                <Route exact path="/slideTypes/:id/:name" name="SlideTypes" element={<SlideType />} />
                <Route exact path="/slideshow/:id/:name" name="Slideshow" element={<Slideshow />} />
              </Routes>
            </Suspense>
          </HashRouter>
        </ToastContextProvider>
      </I18nextProvider>
    </IntlProvider>
  );
}

export default App;
